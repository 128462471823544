// Here you can add other styles
.cardUI {
    transition: transform 0.3s, backdrop-filter 0.3s;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
.cardUI::before {
    content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  backdrop-filter: blur(10px);
  /* color: antiquewhite; */
  }
  
  .cardUI:hover h5 {
    font-size: 1.2em; /* Adjust the font size */
    color: white;
  }
  .cardUI:hover p {
    font-size: 1rem; /* Adjust the font size */
    color: white;
    display: block;
  }
  .cardUI:hover::before {
    opacity: 1;
  }
  .cardUI p{
    color:blue;
    display: none;
  }
  .cardUI h5{
    color: white;
  }
  .card {
    /* Your default card styles go here */
    transition: transform 0.3s ease; /* Add a smooth transition for scaling */
  }
  
  .card-clicked {
    transform: scale(1.1); /* Increase the scale on click */
  }